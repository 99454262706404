body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dateSearchButton {
  background-color: #00c79a;
  border: none;
  padding: 0em 1em;
  margin:2em .3em;
  border-radius: 0.2em;
  color:white;
  font-style:bold;
  transition: 0.4s;
  cursor:pointer;
  outline:none;
}
.dateSearchButton:hover {
  background:#7DE2CB;
  outline:none;
}
.dateInput {
  margin:2em .3em;
}
